import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { EditBar } from "./EditBar";
import { TeamMemberSelect } from "./select/TeamMemberSelect";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReorderIcon from "../../../../components/assets/ReorderIcon";
import placeholder from "../../../../../assets/images/500x500-image-placeholder.jpg";
import TrashIcon from "../../../../components/assets/TrashIcon";

export const ContactInformationModule = ({
  name,
  dragHandle,
  draggable,
  setData,
  data,
  index,
  componentId,
  initialData,
  setFilteredModules,
  filteredModules,
}) => {
  const [selected, setSelected] = useState([]);
  const [active, setActive] = useState(true);

  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(selected);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelected(items);
  }

  function handleDeleteSelect(id) {
    setSelected(selected.filter((item) => item._id !== id));
  }

  useEffect(() => {
    const tempData = [...data];
    tempData[index] = {
      id: componentId,
      active: active,
      teamMembers: selected,
    };
    setData(tempData);
  }, [active, selected, componentId]);

  useEffect(() => {
    if (initialData && initialData[index]) {
      setSelected(data[index].teamMembers);
      setActive(data[index].active);
    }
    if (data && data[index]) {
      setSelected(data[index].teamMembers);
      setActive(data[index].active);
    }
  }, []);

  return (
    <Card className="component-card editor-module contact-information-editor-module">
      <EditBar
        data={data}
        setData={setData}
        draggable={draggable}
        index={index}
        active={active}
        setActive={setActive}
        dragHandle={dragHandle}
        setFilteredModules={setFilteredModules}
        filteredModules={filteredModules}
        componentId={componentId}
      />
      <h2 className="mb-3">{name}</h2>
      <p className="editor-module-field-label">Select Team Members</p>
      <TeamMemberSelect
        selected={selected}
        setSelected={setSelected}
        selectId="contact-information"
      />
      <div className="editor-module-selected-list">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="meet-the-team">
            {(provided) => (
              <ul
                className="editor-module-selected-list-inner"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {selected &&
                  selected.map((team, idx) => (
                    <Draggable
                      key={idx}
                      draggableId={`contact-info-inner_${idx}`}
                      index={idx}
                    >
                      {(provided) => (
                        <li
                          className="editor-module-selected-list-inner-item"
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <div className="d-flex align-items-center">
                            <div className="editor-module-selected-list-item-image">
                              <img
                                src={
                                  team.image
                                    ? team.image[0].fileUrl[1]
                                    : placeholder
                                }
                                alt={
                                  team.image
                                    ? team.image[0].fileAlt
                                    : "placeholder-image"
                                }
                                width={40}
                                height={40}
                              />
                            </div>
                            <div className="editor-module-selected-list-item-name">
                              <p>{team.name}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <button
                              className="transparent me-3"
                              onClick={() => handleDeleteSelect(team._id)}
                            >
                              <TrashIcon height="16px" width="16px" />
                            </button>
                            <div
                              className="reorder-icon"
                              {...provided.dragHandleProps}
                            >
                              <ReorderIcon height="16px" width="16px" />
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Card>
  );
};
